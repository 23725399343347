.btn-primary {
    border: 3px solid #000;
    background-color: transparent;
    font-weight: bold;
    color: #000;
    display: block;
    width: 100%;
    &:disabled {
        background-color: transparent;
        color: inherit;
        border-color: #eee;
        cursor: not-allowed;
        pointer-events: all !important;
        &:active {
            background-color: transparent;
            color: inherit;
            border-color: #eee;
        }
    }
}
#homepage {
    min-height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    .container {
        background-color: #fff;
        align-items: center;
        border: 5px solid #000;
        position: relative;
        z-index: 10000;
        .row {
            overflow: hidden;
            .left {
                position: relative;
                background-color: #fff;
                color: #000;
                .wrapper {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-bottom: 10px;
                }
                h2 {
                    text-align: right;
                    font-size: 14px;
                    margin: 0;
                    font-weight: 600;
                }
                a {
                    text-decoration: none;
                    &:hover {
                        border-bottom: 1px dotted;
                    }
                }
                .logo {
                    text-align: center;
                    font-family: "WindSong", sans-serif;
                    font-weight: 600;
                    margin-top: 20px;
                    padding: 0;
                    font-size: 1.99em;
                    transform: rotate(355deg);
                }
                p {
                    text-align: center;
                    font-weight: 400;
                    font-size: 14px;
                    text-align: center;
                    margin: 0;
                }
                .list-group {
                    .list-group-item {
                        font-size: 12px;
                        text-align: left;
                        font-weight: bold;
                        border: none;
                        border-left: 3px solid #60f;
                        margin: 5px 0;
                        border-radius: 0 !important;
                        abbr {
                            opacity: 1;
                            font-weight: normal;
                            float: right;
                        }
                    }
                }
            }
            .bio {
                align-self: center;
            }
            .right {
                position: relative;
                padding: 0;
            }
        }

        .actions {
            svg {
                color: #fff;
                filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1));
                width: 24px;
                height: 24px;
            }
        }
    }
    #blur {
        filter: blur(5px);
        position: absolute;
        z-index: 0;
        inset: 0;
        background-image: url(../../public/images/bg2.jpg);
        background-position: center;
        background-size: cover;
    }
}
