.image-rotation {
    position: relative;
    overflow: hidden;
    height: 720px;
    display: flex;
    flex-direction: row;
    .actions {
        position: absolute;
        z-index: 1000;
        right: 10px;
        top: 10px;
    }
    .display {
        position: relative;
        height: 720px;
        width: 471px;
        .img {
            position: absolute;
            inset: 0;
            transition-property: opacity;
            transition-timing-function: ease-in-out;
            background-size: cover;
            background-position: center;
        }
        // filter: grayscale(1);
    }
    .slider {
        flex: 1;
        margin-left: 5px;
        max-height: 720px;
        .gradient {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1000;
            height: 100%;
            opacity: 1;
            transition: opacity 1s;
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(255, 255, 255, 1) 100%);
            pointer-events: none; /* This makes the overlay transparent to pointer events */
        }
        &:hover {
            .gradient {
                opacity: 0;
            }
        }
        .tile {
            transition: border 0.1s ease-in-out;
            width: 96px;
            display: inline-block;
            margin-bottom: -6px;
            height: 96px;
            overflow: hidden;
            animation-name: fadeIn;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
            opacity: 0;
            $border-size: 3px;
            &.active,
            &:hover {
                position: relative;
                z-index: 1000;
                box-shadow: 0 0 0px 3px #fff;
            }
            &.active {
                filter: grayscale(0);
            }
            &:hover {
                .img {
                    transform: scale(1.2) rotate(2deg);
                }
            }
            .img {
                transition: transform 0.5s ease-in-out;
                background-size: cover;
                height: 100%;
                width: 100%;
            }
        }
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
