#homepage {
    .container {
        border-color: #fff;
        a {
            color: #fff;
            &:hover {
                border-color: #fff;
            }
        }

        .logo {
            color: #000;
            text-shadow: 5px 5px #eee;
        }

        .list-group {
            .list-group-item {
                background-color: none;
                color: #000;
            }
        }

        .left {
            background-color: #fff;
            color: #000;
        }

        .right {
            background: #fff;
        }
    }
}
