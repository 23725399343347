.dropzone {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
}
#dropzone {
    aside {
        position: absolute;
        top: 75px;
        .btn {
            margin-bottom: 25px;
        }
    }
    .img {
        display: inline-block;
        width: 157px;
        height: 240px;
        background-size: cover;
        background-position: center;
        position: relative;
        svg {
            color: #fff;
            float: right;
            margin-right: 5px;
            margin-top: 5px;
        }
        .progress {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 0;
            background-color: #000;
            & .progress-bar {
                background-color: rgb(89, 0, 255);
            }
        }
    }
}
.total-progress {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
    background-color: #000;
    & .progress-bar {
        background-color: rgb(89, 0, 255);
    }
}
