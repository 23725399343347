#login {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    $primary-color: #12141c;
    .wrapper {
        width: 500px;
        background-color: $primary-color;
        padding: 50px;
        box-shadow: 0 0 10px 0px $primary-color;
        h1 {
            font-size: 24px;
            display: inline-block;
            margin-bottom: 50px;

            border-bottom: 3px solid #06f;
        }
        form {
            .input-group-text {
                background: $primary-color;
                border-color: lighten($primary-color, 15%);
            }
            .form-control {
                padding: 10px;
                background-color: darken($primary-color, 5%);
                border-color: lighten($primary-color, 15%);
                color: #fff;
                box-shadow: none !important;
                &:focus {
                    border-color: #06f;
                }
                &::placeholder {
                    color: lighten($primary-color, 45%);
                }
            }
            .btn {
                margin-top: 25px;
                border-radius: 50px;
                padding: 5px 40px;
                float: right;
                text-transform: uppercase;
            }
        }
    }
}
