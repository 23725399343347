.image-manager {
    .img {
        margin: 5px;
        float: left;
        filter: grayscale(1);
        background-size: cover;
        background-position: center;
        width: 127px;
        height: 194px;
        &:hover {
            .actions {
                display: block;
            }
        }
        img {
            opacity: 0;
            width: 100%;
            position: relative;
            z-index: 0;
            transition: opacity 1s;
            &.loaded {
                opacity: 1;
            }
        }
        .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
        }
        .actions {
            display: none;
            position: absolute;
            right: 10px;
            top: 10px;
            color: #fff;
            svg {
                filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.25));
            }
        }
    }
}
