html,
body,
#root {
    -webkit-font-smoothing: antialiased !important;
    font-smooth: smooth;
    background-color: #000;
    font-size: 14px;
    color: #000;
    height: 100vh;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    -webkit-text-stroke: 0.3px;
}
form {
    font-size: 12px;
}
.form-control {
    border: none;
    border-left: 3px solid #eee;
    margin: 15px 0;
    border-radius: 0;
    &:active,
    &:focus {
        border-color: #60f;
        box-shadow: none;
    }
    &.ok {
        border-color: #0f0;
    }
    &.error {
        border-color: #f00;
    }
}
#admin {
    background-color: #fff;
    color: #000;
    min-height: 100%;
    .nav-link,
    .navbar-text {
        svg {
            vertical-align: bottom !important;
            margin: 0 5px;
        }
        &:hover {
            font-weight: 600;
            cursor: default;
        }
    }
    .container-fluid {
        margin-top: 5px;
        .form-control {
            appearance: auto;
        }
        .form-label {
            margin-top: 10px;
        }
        button {
            margin-top: 25px;
        }
    }
}
